.w-md-editor {
  color: #24292e;
  text-align: left;
  box-shadow: 0 0 0 1px rgba(16, 22, 26, 0.1), 0 0 0 rgba(16, 22, 26, 0), 0 1px 1px rgba(16, 22, 26, 0.2);
  border-radius: 3px;
  padding-bottom: 1px;
  position: relative;
  background-color: #fff;
  font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
}
.w-md-editor-content {
  position: relative;
  border-radius: 0 0 3px 0;
  height: calc(100% - 39.1px);
}
.w-md-editor-input {
  width: 50%;
  height: 100%;
}
.w-md-editor-preview {
  width: 50%;
  box-sizing: border-box;
  box-shadow: inset 1px 0 0 0 #dfdfe0;
  position: absolute;
  padding: 10px 20px;
  overflow: auto;
  top: 0;
  right: 0;
  bottom: 0;
}
.w-md-editor-preview .anchor {
  display: none;
}
.w-md-editor-preview .contains-task-list {
  list-style: none;
}
.w-md-editor-show-preview .w-md-editor-input {
  width: 0%;
  overflow: hidden;
  background-color: #fdfdfd;
}
.w-md-editor-show-preview .w-md-editor-preview {
  width: 100%;
  box-shadow: inset 0 0 0 0;
}
.w-md-editor-show-edit .w-md-editor-input {
  width: 100%;
}
.w-md-editor-show-edit .w-md-editor-preview {
  width: 0%;
  padding: 0;
}
.w-md-editor-fullscreen {
  overflow: hidden;
  position: fixed;
  z-index: 99999;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
.w-md-editor-fullscreen .w-md-editor-content {
  height: 100%;
}
